<template>
  <b-input-group class="control-password-viewer">
    <slot v-bind="{ type }"/>

    <b-input-group-append>
      <button class="password-viewer-toggle form-control" @click.prevent="toggleType" type="button"><lazy-icon :icon="icon"/></button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
const CONTROL_TYPE_ICONS = {
  password: 'b-eye',
  text: 'b-eye-slash'
}
export default {
  name: 'ControlPasswordViewer',
  data () {
    return {
      type: 'password',
      icon: CONTROL_TYPE_ICONS.password
    }
  },
  methods: {
    toggleType () {
      this.type = this.type === 'password' ? 'text' : 'password'
      this.icon = CONTROL_TYPE_ICONS[this.type]
    }
  }
}
</script>

<style lang="scss">
.control-password-viewer {
  input {
    &:-webkit-autofill {
      ~ .input-group-append {
        .password-viewer-toggle {
          -webkit-box-shadow: 0 0 0 1000px theme-color-level('primary', -11) inset;
        }
      }
    }
  }

  .password-viewer-toggle {
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
