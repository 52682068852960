<template>
  <div class="evalanche-form mb-5 mt-5" ref="formContainer">
    <loader v-if="formDefinition === null && formDefinitionError === null" />

    <div class="alert alert-danger" v-else-if="formDefinitionError">
      <p v-html="formDefinitionError"></p>
    </div>

    <template v-else>
      <h1>{{ formDefinition.form.title }}</h1>

      <div class="form-content">
        <div :class="{ 'fade-out': isSubmitting }">
          <div class="alert alert-danger" v-if="serverErrors">
            <p>
              <strong>{{ formDefinition.form.translation.preface_validation }}</strong>
            </p>
            <ul>
              <li v-for="(error, index) in serverErrors" :key="`ServerError_${index}`">{{ error }}</li>
            </ul>
          </div>

          <controls-form
            :controlDefinition="controlsFormDefinition"
            :controlValues="formData"
            :lazy="true"
            :showButtons="true"
            @form:submit="submitFormData"
            :translations="{ submitbutton: formDefinition.form.translation.submit }"
            :buttonAlignment="buttonAlignment"
          />
        </div>

        <loader v-if="isSubmitting" variant="primary" />
      </div>
    </template>
  </div>
</template>

<script>
import Loader from '@/components/private/Loader'
import ControlsForm from '@/components/private/forms/ControlsForm'
import evalancheService from '@/$plugins/services/evalanche-form'

import { COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP } from '@/constants'

const evalancheTypeToControlDiscriminatorMap = {
  text: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.input.type,
  textarea: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.textarea.type,
  select: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.select.type,
  checkbox: { single: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.boolean.type, multi: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.checkbox.type },
  radio: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.radio.type
  // TODO: 'date'
}

export default {
  name: 'EvalancheForm',
  components: {
    Loader,
    ControlsForm
  },
  props: {
    evalancheDomain: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    },
    forceNewProfile: {
      type: Boolean,
      default: () => false
    },
    allowEmptyProfile: {
      type: Boolean,
      default: () => false
    },
    dsgHtmlContent: {
      type: String,
      default: () => null
    },
    buttonAlignment: {
      type: String,
      default: () => 'end'
    }
  },
  data() {
    return {
      formDefinition: null,
      formDefinitionError: null,
      formData: {},
      serverErrors: null,
      isSubmitting: false
    }
  },
  computed: {
    formOptions() {
      return { force_new_profile: false, allow_empty_profile: false }
    },
    formFields() {
      return [...this.formDefinition.fields].sort((a, b) => a.sort - b.sort)
    },
    controlIsVisibleMap() {
      return this.formFields.reduce((result, field) => Object.assign(result, { [field.name]: !(field.hidden ?? false) }), {})
    },
    controlsFormDefinition() {
      const properties = this.formFields.map((field) => {
        let typeDiscriminator = evalancheTypeToControlDiscriminatorMap[field.type] || COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.input.type
        let defaultValue = field.default_value
        let isCheckbox = false

        if (field.type === 'checkbox') {
          isCheckbox = true

          if (defaultValue === '1') {
            defaultValue = true
          } else if (defaultValue === '0') {
            defaultValue = false
          }

          if (typeof typeDiscriminator !== 'string') {
            if (field.options.length > 0) {
              typeDiscriminator = typeDiscriminator.multi
            } else {
              typeDiscriminator = typeDiscriminator.single
            }
          }
        }

        const optionsIsArray = Array.isArray(field.options)
        const haveStandardOptions = optionsIsArray && field.options.length > 0
        let fieldOptionKeys = haveStandardOptions ? field.options.map((option) => option.key) : null
        let fieldOptionValues = haveStandardOptions
          ? field.options.reduce((result, opt) => Object.assign(result, { [opt.key]: opt.value }), {})
          : null

        const optionsIsObject = !optionsIsArray && field.options !== undefined && field.options !== null
        if (optionsIsObject) {
          fieldOptionKeys = Object.keys(field.options).map((optionKey) => field.options[optionKey].key)
          fieldOptionValues = Object.keys(field.options).reduce(
            (result, optionKey) => Object.assign(result, { [field.options[optionKey].key]: field.options[optionKey].value }),
            {}
          )
        }

        const fieldIsVisibleMap = this.controlIsVisibleMap

        const validators = []
        const validatorMessages = {}
        const isRequired = field.required
        if (isRequired) {
          validators.push('requiredValidator')
        }

        if (field.name === 'form_DATENSCHUTZERKLAERUNG') {
          field.label = this.dsgHtmlContent
        }

        const property = {
          attributeTypeDiscriminator: typeDiscriminator,
          name: field.name,
          settings: {
            disabled: field.readOnly ?? false,
            hidden: !fieldIsVisibleMap[field.name],
            validators: validators,
            selectValues: fieldOptionKeys,
            defaultValue: defaultValue
          },
          translations: {
            [this.$store.getters['gui/language:get']]: {
              name: !isCheckbox ? field.label : undefined,
              text: field.label,
              selectValues: fieldOptionValues,
              validators: validatorMessages
            }
          }
        }

        return property
      })

      return {
        properties: properties
      }
    }
  },
  methods: {
    async submitFormData(formData) {
      this.serverErrors = null
      this.isSubmitting = true
      const formResult = this.prepareFormData(formData)

      // set the formData again, just to not flicker the input-content while loading.
      this.formData = formResult

      var result = await evalancheService.submitForm(
        this.evalancheDomain,
        this.formId,
        this.formDefinition.token,
        formResult,
        this.formOptions,
        this.formDefinition
      )

      if (result.error) {
        const fieldNamesSetInvalid = [...result.error.validation_error_attributes]
        this.serverErrors = result.error.validation_error_attributes
          .map((propKey) => {
            const fieldDefinition = this.formFields.find((f) => f.name === propKey)
            return ((fieldDefinition || {}).translation || {}).validation_error
          })
          .filter((x) => x !== '')

        // DIIIIRTY!!!!
        setTimeout(() => {
          for (let index = 0; index < fieldNamesSetInvalid.length; index++) {
            const fieldName = fieldNamesSetInvalid[index]

            // DIRTY add is-invalid on the input-field
            const elements = this.$refs.formContainer.querySelectorAll(`[name="${fieldName}"]`)
            elements.forEach((element) => element.classList.add('is-invalid'))
          }
        }, 250)

        this.isSubmitting = false
      } else {
        location.href = result.result.redirection_url
      }
    },
    prepareFormData(formData) {
      return Object.keys(formData.controls).reduce((data, cKey) => Object.assign(data, { [cKey]: formData.controls[cKey] }), {})
    }
  },
  async mounted() {
    try {
      var formDefinition = await evalancheService.getFormDefinition(this.evalancheDomain, this.formId)
      this.$set(this, 'formDefinition', formDefinition)
    } catch (err) {
      this.formDefinitionError = `Fehler beim laden des Formulars.<br />${err}`
    }
  }
}
</script>

<style lang="scss">
.evalanche-form {
  .form-content {
    position: relative;

    .fade-out {
      position: relative;

      &:after {
        content: '';
        position: absolute;

        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.4);
      }
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
  }
}
</style>
