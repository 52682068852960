// import store from '@/$plugins/store/core'

const xmlSpecialToEscapedMap = {
  '&': '&amp;',
  '"': '&quot;',
  '<': '&lt;',
  '>': '&gt;'
}

const multiValueFieldTypes = ['select']

const encodeXml = (value) => {
  if (value === undefined || value === null) {
    return null
  }

  if (typeof value !== 'string') {
    return value
  }

  return value.replace(/([&"<>])/g, function (str, item) {
    return xmlSpecialToEscapedMap[item]
  })
}

const submitFormInternal = async (evalancheDomain, formId, formToken, formData, submitOptions, formDefinition) => {
  const data = prepareFormData(formDefinition, formData)
  const formBodyData = {
    token: formToken,
    profile_uid: null,
    data: data,
    options: submitOptions
  }

  const formBody = JSON.stringify(formBodyData)

  return await fetch(`https://${evalancheDomain}/api/form/v1/${formId}`, {
    method: 'POST',
    body: formBody
  })
}

const prepareFormData = (formDefinition, formData) => {
  const result = []
  const formDataKeys = Object.keys(formData)
  for (let index = 0; index < formDataKeys.length; index++) {
    const formDataKey = formDataKeys[index]

    const propertyDefinition = formDefinition.fields.find((f) => f.name === formDataKey)
    const formValue = formData[formDataKey]
    const defaultValue = propertyDefinition.default_value

    const isMultiValueField = multiValueFieldTypes.includes(propertyDefinition.type)
    let propertyValue = null
    switch (propertyDefinition.type) {
      case 'checkbox':
        if (Array.isArray(formValue)) {
          propertyValue = formValue.map((val) => (val ? '1' : '0'))
        } else {
          propertyValue = formValue ? '1' : '0'
        }
        break
      case 'select':
      case 'radio':
        propertyValue = `${formData[formDataKey]}`
        break
      default:
        propertyValue = encodeXml(formData[formDataKey])
        break
    }

    if (propertyValue === undefined || propertyValue === null || propertyValue === '') {
      if (defaultValue !== undefined && defaultValue !== null) {
        propertyValue = isMultiValueField ? defaultValue.split('|').filter((x) => x !== '') : defaultValue
      }
    }

    if (propertyValue !== null) {
      result.push({
        field_name: formDataKey,
        value: propertyValue
      })
    }
  }

  return result
}

export default {
  async getFormDefinition(evalancheDomain, formId) {
    const result = await fetch(`https://${evalancheDomain}/api/form/v1/${formId}`, {
      method: 'GET'
    })

    return (await result.json()) || {}
  },
  async submitForm(
    evalancheDomain,
    formId,
    formToken,
    formData,
    submitOptions = { force_new_profile: false, allow_empty_profile: false },
    formDefinition
  ) {
    let submitResult = await submitFormInternal(evalancheDomain, formId, formToken, formData, submitOptions, formDefinition)
    let result = await submitResult.json()

    if (result.error) {
      let updatedFormDefinition
      switch (result.error.code) {
        // token invalid
        case 107:
          updatedFormDefinition = await this.getFormDefinition(evalancheDomain, formId)
          submitResult = submitFormInternal(evalancheDomain, formId, updatedFormDefinition.token, formData, submitOptions, updatedFormDefinition)
          result = await submitResult.json()
          break
      }
    }

    return result
  }
}
